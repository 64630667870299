/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import TvsButton from 'components/elements/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ModalBody, ModalFooter } from 'reactstrap';

const AdvancePaymentModal = ({ setShowAdvancePaymentModal, link }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleYes = () => {
    setShowAdvancePaymentModal(false);
    history.push(link);
  };

  const handleNo = () => {
    setShowAdvancePaymentModal(false);
  };

  return (
    <>
      <ModalBody className="p-5 flex justify-content-center">
        <span>{t('This account is fully repaid this month.')}</span>
        <br />
        <span>{t('Do you want to record an advanced payment now?')}</span>
      </ModalBody>
      <ModalFooter className="text-center justify-content-center">
        <TvsButton onClick={() => handleNo()} color="primary" outline className="px-5 mr-3">
          {t('No')}
        </TvsButton>
        <TvsButton onClick={() => handleYes()} color="primary" className="px-5 ml-3">
          {t('Yes')}
        </TvsButton>
      </ModalFooter>
    </>
  );
};

export default AdvancePaymentModal;
