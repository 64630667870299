import React from 'react';

function NavigationPanelIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40" {...props}>
      <path
        fill="#fff"
        d="M10.18 15.105c-1.361 0-2.18-.819-2.18-2.24V10.24C8 8.83 8.819 8 10.18 8h2.733c1.373 0 2.18.83 2.18 2.24v2.625c0 1.421-.807 2.24-2.18 2.24H10.18zm8.694 0c-1.373 0-2.18-.819-2.18-2.24V10.24c0-1.41.807-2.24 2.18-2.24h2.734c1.372 0 2.18.83 2.18 2.24v2.625c0 1.421-.808 2.24-2.18 2.24h-2.734zm8.707 0c-1.373 0-2.192-.819-2.192-2.24V10.24c0-1.41.819-2.24 2.192-2.24h2.733c1.36 0 2.18.83 2.18 2.24v2.625c0 1.421-.82 2.24-2.18 2.24h-2.733zM10.18 23.546c-1.361 0-2.18-.806-2.18-2.215v-2.65c0-1.409.819-2.215 2.18-2.215h2.733c1.373 0 2.18.806 2.18 2.215v2.65c0 1.409-.807 2.215-2.18 2.215H10.18zm8.694 0c-1.373 0-2.18-.806-2.18-2.215v-2.65c0-1.409.807-2.215 2.18-2.215h2.734c1.372 0 2.18.806 2.18 2.215v2.65c0 1.409-.808 2.215-2.18 2.215h-2.734zm8.707 0c-1.373 0-2.192-.806-2.192-2.215v-2.65c0-1.409.819-2.215 2.192-2.215h2.733c1.36 0 2.18.806 2.18 2.215v2.65c0 1.409-.82 2.215-2.18 2.215h-2.733zM10.18 32C8.819 32 8 31.181 8 29.772v-2.637c0-1.409.819-2.228 2.18-2.228h2.733c1.373 0 2.18.819 2.18 2.228v2.637c0 1.41-.807 2.228-2.18 2.228H10.18zm8.694 0c-1.373 0-2.18-.819-2.18-2.228v-2.637c0-1.409.807-2.228 2.18-2.228h2.734c1.372 0 2.18.819 2.18 2.228v2.637c0 1.41-.808 2.228-2.18 2.228h-2.734zm8.707 0c-1.373 0-2.192-.819-2.192-2.228v-2.637c0-1.409.819-2.228 2.192-2.228h2.733c1.36 0 2.18.819 2.18 2.228v2.637c0 1.41-.82 2.228-2.18 2.228h-2.733z"
      />
    </svg>
  );
}

export default NavigationPanelIcon;
