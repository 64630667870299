import { isStyleObject } from 'constant';
import { useEffect, useRef } from 'react';

const SingleOtpInput = (props: any) => {
  const {
    placeholder,
    separator,
    isLastChild,
    inputStyle,
    focus,
    isDisabled,
    hasErrored,
    errorStyle,
    focusStyle,
    disabledStyle,
    shouldAutoFocus,
    index,
    value,
    className,
    ...rest
  } = props;

  const input = useRef<any>();
  const getClasses = (...classes: any) => classes.filter((c: any) => !isStyleObject(c) && c !== false).join(' ');
  const getType = () => {
    return 'tel';
  };

  useEffect(() => {
    if (focus && shouldAutoFocus) input.current.focus();
  }, [shouldAutoFocus, focus]);

  return (
    <div className={className} style={{ display: 'flex', alignItems: 'center' }}>
      <input
        aria-label={`${index === 0 ? 'Please enter verification code. ' : ''} Digit ${index + 1}`}
        autoComplete="off"
        style={{
          width: '1em',
          textAlign: 'center',
          ...(isStyleObject(inputStyle) && inputStyle),
          ...(focus && isStyleObject(focusStyle) && focusStyle),
          ...(isDisabled && isStyleObject(disabledStyle) && disabledStyle),
          ...(hasErrored && isStyleObject(errorStyle) && errorStyle),
        }}
        placeholder={placeholder}
        className={getClasses(inputStyle, focus && focusStyle, isDisabled && disabledStyle, hasErrored && errorStyle)}
        type={getType()}
        maxLength="1"
        ref={input}
        disabled={isDisabled}
        value={value || ''}
        {...rest}
      />
      {!isLastChild && separator}
    </div>
  );
};

export default SingleOtpInput;
