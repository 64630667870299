import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import NavigationPanelIcon from 'constant/svgIcons/NavigationPanel';
import { Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, Button } from 'reactstrap';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const NavigationPanel = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { auth, crudState } = useSelector((state: any) => state);
  const open = Boolean(anchorEl);
  const [navigationPanelOpen, setNavigationPanelOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span className="d-flex align-items-center mr-2">
      <Button
        id="navigation-panel"
        onClick={handleClick}
        aria-controls={open ? 'navigation-panel' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{ width: '48px', height: '55px', minWidth: '20px' }}
        style={{ backgroundColor: 'transparent', border: '0px' }}
      >
        <HoverDiv style={{ paddingTop: '8px' }}>
          <NavigationPanelIcon />
        </HoverDiv>
      </Button>
      <Menu
        id="navigation-panel"
        disableScrollLock={true}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'navigation-panel',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          top: 9,
          paddingBottom: 0,
          '.MuiMenu-paper': {
            borderRadius: 0,
            boxShadow: '0 0 5px #3333',
          },
          '.MuiList-root': {
            padding: 4,
          },
        }}
      >
        <Grid container spacing={2} style={{ width: 'calc(100% + 170px)', height: '100%' }}>
          <Grid item xs={4}>
            <Card onClick={() => window.location.replace(`${process.env.REACT_APP_LOGIN_DOMAIN}/redirect?module=MFT`)}>
              <img
                style={{ height: '56px', width: '56px' }}
                src={`${process.env.REACT_APP_LOGIN_DOMAIN}/images/icon_leads.png`}
              />
              <CardText>{t('Leads Management')}</CardText>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card onClick={() => window.location.replace(`${process.env.REACT_APP_LOGIN_DOMAIN}/redirect?module=LOS`)}>
              <img
                style={{ height: '56px', width: '56px' }}
                src={`${process.env.REACT_APP_LOGIN_DOMAIN}/images/icon_loan.png`}
              />
              <CardText>{t('Loan Origination')}</CardText>
            </Card>
          </Grid>
        </Grid>
      </Menu>
    </span>
  );
};

const MenuItemStyle = styled(MenuItem)`
  background: white;
  align-items: start;
  &:hover {
    background: rgba(243, 243, 254, 1);
  }
`;

const HoverDiv = styled.div`
  width: 48px;
  height: 55px;
  &:hover {
    background-color: #ffffff4d;
  }
`;

// const CardImage = styled(img)`
//   width: 56px;
//   height: 56px;
//   object-fit: contain;
//   object-position: center;
//   margin-bottom: 12px;
// `;

const CardText = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #6956ba;
  margin-top: 10px;
  font-family: Noto Sans, sans-serif;
  font-variation-settings: 'wdth' 100;
  width: 80%;
`;

const Card = styled.div`
  height: 150px;
  width: 110px;
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    background-color: #f2f3ff;
    cursor: pointer;
  }
`;

export default NavigationPanel;
