import { useState, useEffect } from 'react';

import { Localization } from 'constant';

type ZendeskChatWidgetTypes = {
  hideOnLoad?: boolean;
  showChat?: boolean;
  onFinishScriptLoad?: () => void;
};
const ZendeskChatWidget = (props: ZendeskChatWidgetTypes) => {
  const { hideOnLoad = false, showChat = false, onFinishScriptLoad } = props;
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (document.getElementById('ze-snippet') !== null) {
      setLoaded(true);
      onFinishScriptLoad && onFinishScriptLoad();
    }
    if (document.getElementById('ze-snippet') === null && !loaded) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${Localization.zendeskToken}`;
      script.async = true;
      document.body.appendChild(script);
      const scriptLoadedPromise = new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
      });
      scriptLoadedPromise.then(() => {
        onFinishScriptLoad && onFinishScriptLoad();
        if (hideOnLoad) {
          // @ts-ignore
          zE('webWidget', 'hide');
        }
      });
      setLoaded(true);
    }
  }, []);
  useEffect(() => {
    if (loaded) {
      if (showChat) {
        // @ts-ignore
        zE('webWidget', 'show');
      } else {
        // @ts-ignore
        zE('webWidget', 'hide');
      }
    }
  }, [showChat]);
  return null;
};
export default ZendeskChatWidget;
