const PortraitUser = ({ size }: any) => {
  return (
    <svg
      style={{ width: size }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.81093 20C4.95983 20 4 19.0487 4 17.2148V6.78522C4 4.95126 4.95983 4 6.81093 4H17.1891C19.0402 4 20 4.95126 20 6.78522V17.2148C20 19.0402 19.0402 20 17.1891 20H6.81093ZM5.6797 17.0691C5.6797 17.7204 5.94537 18.1232 6.44242 18.2603C7.21371 16.2721 9.39047 14.9009 12.0043 14.9009C14.6181 14.9009 16.7949 16.2721 17.5661 18.2517C18.0546 18.1146 18.3203 17.7204 18.3203 17.0691V6.92234C18.3203 6.09106 17.8747 5.6797 17.0862 5.6797H6.91377C6.11676 5.6797 5.6797 6.09106 5.6797 6.92234V17.0691ZM12.0043 13.5212C10.3674 13.504 9.09052 12.1328 9.08195 10.3246C9.07338 8.61061 10.3674 7.188 12.0043 7.188C13.6411 7.188 14.9266 8.61061 14.9266 10.3246C14.9266 12.1328 13.6411 13.5297 12.0043 13.5212Z"
        fill="#3548C9"
      />
    </svg>
  );
};

export default PortraitUser;
