/* eslint-disable no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';

import { toastr } from 'react-redux-toastr';
import BidderAPI from 'utils/service/BidderAPI';

import { APPROVE_BIDDER, CREATE_BIDDER, GET_DETAIL_BIDDER, UPDATE_BIDDER, GET_NUMBER_BIDDER } from './action';
import { CrudActionBidder } from './types';

function* createBidderSaga(action: CrudActionBidder) {
  try {
    const res = yield call(BidderAPI.createBidder, action.payload);
    const { data } = res;
    if (data) {
      toastr.success('', 'Added bidder have been sent for approval', { timeOut: 3000 });
    }
  } catch (error) {
    toastr.error('', error.message, { timeOut: 3000 });
  }
}

function* getDetailBidderSaga(action: CrudActionBidder) {
  const { id, onSuccess } = action.payload;
  try {
    const res = yield call(BidderAPI.getDetailBidder, id);
    const { data } = res;
    if (data) {
      onSuccess(data);
    }
  } catch (error) {
    toastr.error('', error.message, { timeOut: 3000 });
  }
}

function* updateBidderSaga(action: CrudActionBidder) {
  const { id, paramsBidder } = action.payload;
  try {
    const res = yield call(BidderAPI.updateBidder, id, paramsBidder);
    const { data } = res;
    if (data) {
      toastr.success('', 'Edited bidder have been sent for approval”', { timeOut: 3000 });
    }
  } catch (error) {
    toastr.error('', error.message, { timeOut: 3000 });
  }
}

function* approveBidderSaga(action: CrudActionBidder) {
  const { id, status, onSuccess, message } = action.payload;
  try {
    let res;
    message
      ? (res = yield call(BidderAPI.approveBidder, id, status, message))
      : (res = yield call(BidderAPI.approveBidder, id, status));

    const { data } = res;
    if (data) {
      onSuccess(data);
      toastr.success('', 'Approval Bidder Success', { timeOut: 3000 });
    }
  } catch (error) {
    toastr.error('', error.message, { timeOut: 3000 });
  }
}

function* getSeqNumberSaga(action: CrudActionBidder) {
  const { onSuccess } = action.payload;
  try {
    const res = yield call(BidderAPI.getNumberBidder);
    const { data } = res;
    if (data) {
      onSuccess(data);
    }
  } catch (error) {
    console.error(error);
  }
}
function* biddersSaga() {
  yield takeLatest(CREATE_BIDDER, createBidderSaga);
  yield takeLatest(GET_DETAIL_BIDDER, getDetailBidderSaga);
  yield takeLatest(UPDATE_BIDDER, updateBidderSaga);
  yield takeLatest(APPROVE_BIDDER, approveBidderSaga);
  yield takeLatest(GET_NUMBER_BIDDER, getSeqNumberSaga);
}

export default biddersSaga;
