import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/types';
import { fetchCollection, updateNotification } from 'redux/crud/action';
import { Container, Button } from 'reactstrap';
import styles from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Localization } from 'constant';
import { AppContext } from 'helpers';

SwiperCore.use([Autoplay]);

const NotificationSwiper = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((st: State) => st);
  const { crudState } = useSelector((state: any) => state);
  const { notificationsRetrieved, setNotificationsRetrieved } = useContext(AppContext);

  const [notifications, setNotifications] = useState(
    crudState?.notifications?.collections?.otherInfo?.notifications || [],
  );

  useEffect(() => {
    // fetch non-sensitive notifications if user is not logged in
    if (!auth?.user?.id) {
      dispatch(
        fetchCollection('notifications', 'cms/non-sensitive-notifications', {
          filter: {
            client: Localization.clientName,
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    // fetch sensitive notifications when user is logged in
    if (auth?.user?.id && !notificationsRetrieved && auth?.user?.role !== 'R') {
      dispatch(
        fetchCollection('notifications', 'cms/sensitive-notifications', {
          filter: {
            client: Localization.clientName,
            role: auth.user.role,
          },
        }),
      );
      setNotificationsRetrieved(true);
    }
  }, [auth?.user?.id]);

  useEffect(() => {
    if (crudState?.notifications?.collections?.otherInfo?.notifications) {
      setNotifications(crudState?.notifications?.collections?.otherInfo?.notifications || []);
    }
  }, [crudState?.notifications?.collections?.otherInfo?.notifications]);

  function handleDelete(notificationToDelete) {
    const updatedNotifications = notifications.filter((notification) => {
      return notification !== notificationToDelete;
    });

    // setNotifications(updatedNotifications);
    dispatch(updateNotification('notifications', { notifications: updatedNotifications }));
  }

  const Div =
    notifications.length > 0
      ? styles.div`
        padding: 15px;
        position: absolute;
        width: 100%;
        height: auto;
        text-align: center;
        background: #6956BA;
        color: white;
        z-index: 10001;
        opacity: 0.9;
        `
      : styles.div``;

  if (notifications.length <= 0) {
    return null;
  }

  return (
    <Div>
      <Container fluid={true}>
        <div>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={notifications.length > 1 ? { delay: 7000 } : false}
            loop={notifications.length > 1}
          >
            {notifications.length > 0 &&
              notifications.map((notification, index) => (
                <SwiperSlide
                  key={notification.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                    height: 'auto',
                  }}
                >
                  <div style={{ cursor: 'pointer', alignSelf: 'center' }}>
                    {index + 1}/{notifications.length}
                  </div>
                  <div
                    style={{
                      paddingLeft: '15px',
                      paddingRight: '15px',
                      alignSelf: 'center',
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}
                  >
                    <div style={{ alignSelf: 'center', marginRight: 10 }}>{notification.message}</div>
                    {notification.external_url ? (
                      <Button
                        style={{
                          backgroundColor: 'white',
                          border: 1,
                          alignSelf: 'center',
                          justifyContent: 'center',
                          borderRadius: 8,
                        }}
                        onClick={() => window.open(notification.external_url, '_blank')}
                      >
                        <text
                          style={{
                            color: '#6956BA',
                            fontSize: 14,
                            fontWeight: 700,
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Learn More
                        </text>
                      </Button>
                    ) : null}
                  </div>
                  <CloseIcon
                    onClick={() => handleDelete(notification)}
                    style={{ cursor: 'pointer', alignSelf: 'center' }}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </Container>
    </Div>
  );
};

export default NotificationSwiper;
