import React from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, CSVProps } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { PaginationProps } from 'reactstrap';
import styled from 'styled-components';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { PiCaretUpDownBold } from 'react-icons/pi';
import { CiCloudOn } from 'react-icons/ci';
import themeColors from 'themes/themeColors';
import { Checkbox } from '@mantine/core';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import LoadingSpinner from './loadingSpinner';

function NoData() {
  return (
    <>
      <CiCloudOn size={84} color={themeColors.Grey100} />
      <p
        style={{
          fontFamily: 'inter',
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '24px',
          color: themeColors.Grey300,
        }}
      >
        No data yet
      </p>
      <p
        style={{
          fontFamily: 'inter',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
          color: themeColors.Grey300,
        }}
      >
        Check again regularly for new data
      </p>
    </>
  );
}

export type TvsTableProps = {
  totalSize?: number;
  sizePerPage?: number;
  columns: any[];
  data: any[];
  page?: number;
  onTableChange?: (type: string, status: any) => void;
  keyField?: string;
  loading?: boolean;
  paginateTable?: boolean;
  rowStyle?: any;
  selectRow?: SelectRowProps<any>;
  downloadProps?: { styles?: React.CSSProperties; props?: CSVProps };
};

const TvsTable = ({
  data,
  totalSize,
  sizePerPage,
  columns,
  page,
  onTableChange,
  keyField,
  loading,
  paginateTable = true,
  selectRow,
  rowStyle,
  downloadProps,
}: TvsTableProps) => {
  const { t } = useTranslation();
  const { ExportCSVButton } = CSVExport;
  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: t('First'),
    prePageText: <div className="table-arrow-left" />,
    nextPageText: 'Next',
    lastPageText: t('Last'),
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    totalSize,
    sizePerPage,
  };

  const rowStyleDefault = (row: any, rowIndex: number) => {
    const style = {
      color: '#5A6168',
    };
    // if (rowIndex % 2 === 0) {
    //   return {
    //     background: 'rgba(42, 42, 62, 0.04)',
    //     ...style,
    //   };
    // }
    return {
      background: '#fff',
      ...style,
    };
  };

  const selectRowDefault = {
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <Checkbox indeterminate={indeterminate} color={themeColors.Primary500} {...rest} />
    ),
    selectionRenderer: ({ mode, ...rest }) => <Checkbox color={themeColors.Primary500} {...rest} />,
  };

  if (paginateTable) {
    return (
      <>
        <LoadingSpinner loading={loading} />
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }: PaginationProps) => {
            // eslint-disable-next-line no-param-reassign
            paginationProps.dataSize = totalSize;
            // eslint-disable-next-line no-param-reassign
            paginationProps.page = page;

            return (
              <ToolkitProvider
                keyField={keyField || 'id'}
                columns={columns}
                data={data}
                search
                exportCSV={downloadProps?.props}
              >
                {(toolkitprops) => (
                  <>
                    {downloadProps ? (
                      <ExportCSVButton
                        style={{
                          position: 'absolute',
                          top: 170,
                          right: 20,
                          backgroundColor: themeColors.Primary500,
                          borderRadius: 8,
                          color: themeColors.White,
                          fontFamily: 'inter',
                          fontSize: 16,
                          fontWeight: 500,
                          padding: '10px 12px 10px 12px',
                          ...downloadProps.styles,
                        }}
                        {...toolkitprops.csvProps}
                      >
                        <IoCloudDownloadOutline style={{ marginRight: 8 }} />
                        Download
                      </ExportCSVButton>
                    ) : null}

                    <BootstrapTable
                      bordered={false}
                      {...toolkitprops.baseProps}
                      {...paginationTableProps}
                      remote={{ filter: true, sort: true, pagination: true }}
                      onTableChange={onTableChange}
                      noDataIndication={loading ? t('Loading') : <NoData />}
                      rowStyle={rowStyle || rowStyleDefault}
                      loading={loading}
                      selectRow={
                        selectRow && {
                          ...selectRowDefault,
                          ...selectRow,
                        }
                      }
                      overlay={overlayFactory({ background: '#fbfbfc' })}
                    />

                    <div className="d-flex justify-content-center table-pagination my-5">
                      <PaginationListStandalone {...paginationProps} className="d-inline mr-auto" />
                    </div>
                  </>
                )}
              </ToolkitProvider>
            );
          }}
        </PaginationProvider>
      </>
    );
  }
  return (
    <>
      <LoadingSpinner loading={loading} />
      <ToolkitProvider
        keyField={keyField || 'id'}
        columns={columns}
        data={data}
        search
        exportCSV={downloadProps?.props}
      >
        {(toolkitprops) => (
          <>
            {downloadProps ? (
              <ExportCSVButton
                style={{
                  position: 'absolute',
                  top: 170,
                  right: 20,
                  backgroundColor: themeColors.Primary500,
                  borderRadius: 8,
                  color: themeColors.White,
                  fontFamily: 'inter',
                  fontSize: 16,
                  fontWeight: 500,
                  padding: '10px 12px 10px 12px',
                  ...downloadProps.styles,
                }}
                {...toolkitprops.csvProps}
              >
                <IoCloudDownloadOutline style={{ marginRight: 8 }} />
                Download
              </ExportCSVButton>
            ) : null}

            <BootstrapTable
              bordered={false}
              {...toolkitprops.baseProps}
              remote={{ filter: true, sort: true, pagination: true }}
              onTableChange={onTableChange}
              noDataIndication={loading ? t('Loading') : <NoData />}
              rowStyle={rowStyle || rowStyleDefault}
              wrapperClasses="table-responsive"
              rowClasses="text-nowrap"
              selectRow={
                selectRow && {
                  ...selectRowDefault,
                  ...selectRow,
                }
              }
              overlay={overlayFactory({ background: '#fbfbfc' })}
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
};

export default TvsTable;

export const sortCaret = (column: any, sort: any) => {
  const IStyled = styled.i`
    display: inline-block;
    margin-left: 10px;
    &.unsort {
      border: solid #e5e5e5;
      border-width: 0 2px 2px 0;
      padding: 3px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      margin-bottom: 2px;
    }
    &.up {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 5px solid #5a6168;
      margin-bottom: 2px;
    }
    &.down {
      margin-bottom: 2px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-top: 5px solid #5a6168;
    }
  `;

  if (!sort || sort.s !== column.dataField) {
    return <PiCaretUpDownBold color="#395278" size={20} />;
  }
  if (sort.o === 'asc') {
    return <MdKeyboardArrowUp color="#395278" size={20} />;
  }
  if (sort.o === 'desc') {
    return <MdKeyboardArrowDown color="#395278" size={20} />;
  }
  return null;
};
