/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { STAGE } from 'constant';
import Arrow from 'components/layouts/Arrow';

interface Props {
  handleChange: (stage: string) => void;
}

const StageDropdown = ({ handleChange }: Props) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [list, setList] = useState<any>([]);
  const [active, setActive] = useState<any>();

  useEffect(() => {
    if (list.length === 0) renderStageList();
  }, []);

  const renderStageList = () => {
    setList(STAGE);
    setActive(STAGE[0]);
  };

  const handleSelectItem = (stage: string) => {
    setActive(stage);
    handleChange(stage);
  };

  const renderItem = (item: any) => {
    return (
      <DropdownItemStyle
        key={item}
        onClick={() => {
          handleSelectItem(item);
        }}
      >
        {t(item)}
      </DropdownItemStyle>
    );
  };

  const dropDown = () => {
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={{
          border: '1px solid #6956BA',
          height: '56px',
          borderRadius: '8px',
          background: '#fff',
          width: '200px',
        }}
      >
        <DropdownToggleStyle color="x" className="p-3 border-0 justify-content-between" role="button">
          <div style={{ width: 'max-content' }}>{t(active ? `${active}` : '')}</div>
          <div style={{ width: '100px' }} />
          <div style={{ alignSelf: 'center', right: '10px', position: 'absolute' }}>
            <Arrow border={2} length={9} color="#666666" rotate={45} />
          </div>
        </DropdownToggleStyle>

        <DropdownMenuStyle className="mt-2">{list && list.map((item: any) => renderItem(item))}</DropdownMenuStyle>
      </Dropdown>
    );
  };

  return dropDown();
};

export const DropdownItemStyle = styled(DropdownItem)`
  &.active {
    background: #ffcc4d;
  }
`;

export const DropdownMenuStyle = styled(DropdownMenu)`
  ::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 100em;
  }
  ::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 100em;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
`;

export const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;

export default StageDropdown;
