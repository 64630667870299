/* eslint-disable import/no-anonymous-default-export */
const StatusColor = {
  Success: '#15A683',
  Error: '#E15C5C',
  Warning: '#ffcc00',
};

const Colors = {
  transparent: 'transparent',
  Primary: '#6956BA',
  Primary300: '#B1B6FF',
  Primary200: '#9EA5E5',
  Primary25: '#F3F3FB',
  Primary100: '#C5C8EF',
  Primary400: '#5665D2',
  Primary500: '#3548C9',
  Primary50: '#F2F3FF',
  White: '#fff',
  Gray: '#ccc',
  Lavender: '#DDE0FF',
  Gray999: '#999',
  Gray666: '#666',
  Gray333: '#333',
  Gray111: '#E4E4E4',
  Grey500: '#395278',
  Grey800: '#253858',
  Grey400: '#566A89',
  Grey300: '#74849C',
  Grey25: '#F4F5F7',
  Grey100: '#C0C9D5',
  Grey50: '#e7e9ed',
  Grey200: '#99A6B9',
  BgType1: '#F2F3FF',
  Black: '#000',
  Platinum: '#E7E7E7',
  PlatinumC: '#CCCCCC',
  Green: '#15A683',
  Disabled: '#d3d3d3',
  DisabledLight: '#E4E4E4',
  Disabled_e3: '#e5e3e3',
  Error500: '#EB4553',
  Error300: '#D97683',
  Error50: '#FBEBF0',
  Warning500: '#FF9600',
  Warning50: '#FFF3DF',
  Success500: '#42AD47',
  Success50: '#E7F5E8',

  alpha: {
    alpha_FF: 'FF',
    alpha_09: 'E6',
    alpha_08: 'CD',
    alpha_07: 'B4',
    alpha_06: '9B',
    alpha_05: '82',
    alpha_04: '69',
    alpha_03: '37',
    alpha_02: '1E',
    alpha_01: '05',
  },

  ...StatusColor,
};

const AppliedAlpha = {
  black_Alpha10: `${Colors.Black}10`,
};
export default {
  ...Colors,
  ...AppliedAlpha,
};
