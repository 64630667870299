import styled from '@emotion/styled';
import { Button } from 'reactstrap';
import themeColors from 'themes/themeColors';

const TvsButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 18px;
  height: 48px;
  min-width: 48px;

  &&.btn-primary {
    background-color: ${themeColors.Primary500};
    border: 1px solid ${themeColors.Primary500};
  }

  &&.btn-outline-primary {
    border: 1px solid ${themeColors.Primary500};
    color: ${themeColors.Primary500};
    &:hover {
      background-color: ${themeColors.Primary500};
      color: #fff;
    }
  }

  && {
    border-radius: 8px;
  }
`;

export default TvsButton;
