const SwapIcon = ({ size }: any) => {
  return (
    <svg
      style={{ width: size }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6391 20C11.5054 20 11.293 19.9449 11.0885 19.8348C6.57325 17.3648 5 16.2399 5 13.3766V7.42183C5 6.49361 5.36185 6.15536 6.14061 5.83284C7.01377 5.47099 9.96362 4.37758 10.821 4.14159C11.0806 4.0708 11.3795 4 11.6391 4C11.9066 4 12.2055 4.05506 12.4572 4.14159C13.3225 4.42478 16.2724 5.46313 17.1455 5.83284C17.9243 6.16323 18.2861 6.49361 18.2861 7.42183V13.3766C18.2861 16.2399 16.7129 17.3727 12.1976 19.8348C11.9931 19.9449 11.7807 20 11.6391 20ZM11.6391 18.3481C11.7571 18.3481 11.8673 18.3088 12.0954 18.1672C15.6509 16.0275 16.8073 15.3982 16.8073 13.0777V7.67355C16.8073 7.39823 16.7522 7.28024 16.532 7.20157C15.352 6.77679 13.1101 5.9823 12.0482 5.56539C11.8751 5.50246 11.7571 5.47886 11.6391 5.47886C11.529 5.47886 11.4031 5.50246 11.2301 5.56539C10.176 5.9823 7.91052 6.7296 6.74631 7.20157C6.53392 7.2881 6.47099 7.39823 6.47099 7.67355V13.0777C6.47099 15.3825 7.588 15.9803 11.1908 18.1672C11.411 18.3088 11.529 18.3481 11.6391 18.3481ZM12.3314 8.33432C12.3314 7.97247 12.6932 7.84661 12.9685 8.0354L14.88 9.32547C15.1396 9.50639 15.1396 9.83677 14.8958 9.9941L12.9685 11.292C12.6932 11.4887 12.3314 11.355 12.3314 10.9853V10.2144H8.58702C8.2881 10.2144 8.03638 9.9705 8.03638 9.66372C8.03638 9.35693 8.2881 9.10521 8.58702 9.10521H12.3314V8.33432ZM8.21731 13.9823C7.97345 13.8171 7.97345 13.4867 8.23304 13.3137L10.1445 12.0236C10.4199 11.8348 10.7817 11.9528 10.7817 12.3147V13.0934H14.5261C14.825 13.0934 15.0767 13.3451 15.0767 13.6519C15.0767 13.9508 14.825 14.2026 14.5261 14.2026H10.7817V14.9735C10.7817 15.3432 10.4199 15.469 10.1445 15.2802L8.21731 13.9823Z"
        fill="#3548C9"
      />
    </svg>
  );
};

export default SwapIcon;
