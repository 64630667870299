/* eslint-disable react/require-default-props */
import { isStyleObject } from 'constant';
import { CSSProperties, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SingleOtpInput from './Input';

interface EventTarget {
  addEventListener(type: string, listener: EventListenerOrEventListenerObject, useCapture?: boolean): void;
  dispatchEvent(evt: Event): boolean;
  removeEventListener(type: string, listener: EventListenerOrEventListenerObject, useCapture?: boolean): void;
}

interface SyntheticEvent {
  bubbles: boolean;
  cancelable: boolean;
  currentTarget: EventTarget;
  defaultPrevented: boolean;
  eventPhase: number;
  isTrusted: boolean;
  nativeEvent: {
    inputType: any;
    data: any;
  };
  preventDefault(): void;
  stopPropagation(): void;
  target: EventTarget;
  timeStamp: Date;
  type: string;
  keyCode: number;
  key: string;
  clipboardData: any;
}

const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;

interface OtpInputProps {
  id: string;
  numInputs: number;
  inputStyle?: CSSProperties;
  focusStyle?: CSSProperties;
  isDisabled?: boolean;
  disabledStyle?: CSSProperties;
  hasErrored?: boolean;
  errorStyle?: CSSProperties;
  shouldAutoFocus: boolean;
  containerStyle?: CSSProperties;
}
const OtpInput = ({
  id,
  numInputs,
  inputStyle,
  focusStyle,
  isDisabled,
  disabledStyle,
  hasErrored,
  errorStyle,
  shouldAutoFocus,
  containerStyle,
}: OtpInputProps) => {
  const { setValue: setValues, watch } = useFormContext();
  const [activeInput, setActiveInput] = useState(0);
  const [otp, setOtp] = useState<any[]>([]);

  useEffect(() => {
    if (watch(id)) setOtp(watch(id).toString().split(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(id)]);

  const handleOnChange = (e: SyntheticEvent) => {
    const simpleInput = (e.target as HTMLInputElement).value;
    if (!isNaN(parseInt(simpleInput, 10))) {
      changeCodeAtFocus(simpleInput);
    }
  };

  // Change OTP value at focused input
  const changeCodeAtFocus = (input: string) => {
    const tempOtp: any = [...otp];
    tempOtp[activeInput] = input;
    handleOtpChange(tempOtp);
  };

  // Helper to return OTP from input
  const handleOtpChange = (updatedOtp: string[]) => {
    const otpValue = updatedOtp.join('');
    setValues(id, otpValue);
  };

  // Focus on input by index
  const focusInput = (input: number) => {
    const tempActiveInput = Math.max(Math.min(numInputs - 1, input), 0);
    setActiveInput(tempActiveInput);
  };

  const focusPrevInput = () => {
    focusInput(activeInput - 1);
  };

  // Focus on next input
  const focusNextInput = () => {
    focusInput(activeInput + 1);
  };

  const handleOnKeyDown = (e: SyntheticEvent) => {
    if (e.keyCode === BACKSPACE || e.key === 'Backspace') {
      e.preventDefault();
      changeCodeAtFocus('');
      focusPrevInput();
    } else if (e.keyCode === DELETE || e.key === 'Delete') {
      e.preventDefault();
      changeCodeAtFocus('');
    } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault();
      focusPrevInput();
    } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
      e.preventDefault();
      focusNextInput();
    } else if (e.keyCode === SPACEBAR || e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
      e.preventDefault();
    }
  };
  const handleOnInput = (e: SyntheticEvent) => {
    const simpleInput = (e.target as HTMLInputElement).value;
    if (!isNaN(parseInt(simpleInput, 10))) {
      focusNextInput();
    } else {
      const { nativeEvent } = e;
      if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
        e.preventDefault();
        changeCodeAtFocus('');
        focusPrevInput();
      }
    }
  };

  const handleOnPaste = (e: SyntheticEvent) => {
    e.preventDefault();
    if (isDisabled) {
      return;
    }
    let nextActiveInput = activeInput;

    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');
    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift();
        nextActiveInput += 1;
      }
    }
    setActiveInput(nextActiveInput);
    focusInput(nextActiveInput);
    handleOtpChange(otp);
  };

  const renderInputs = () => {
    const inputs = [];

    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <SingleOtpInput
          key={i}
          index={i}
          focus={activeInput === i}
          value={otp && otp[i]}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onInput={handleOnInput}
          onPaste={handleOnPaste}
          onFocus={(e: any) => {
            setActiveInput(i);
            e.target.select();
          }}
          onBlur={() => setActiveInput(-1)}
          inputStyle={inputStyle}
          focusStyle={focusStyle}
          isLastChild={i === numInputs - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          hasErrored={hasErrored}
          errorStyle={errorStyle}
          shouldAutoFocus={shouldAutoFocus}
        />,
      );
    }
    return inputs;
  };

  return (
    <div
      style={{
        display: 'flex',
        ...(isStyleObject(containerStyle) && containerStyle),
      }}
    >
      {renderInputs()}
    </div>
  );
};

export default OtpInput;
