/* eslint-disable react/no-danger */
import { Avatar, Badge, BadgeProps, Button, Menu, MenuItem, styled } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useEffect } from 'react';
import './notification.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchCollection } from 'redux/crud/action';
import { update } from 'utils/apiProvider';
import styles from 'styled-components';
import { clsx } from '@mantine/core';
import themeColors from 'themes/themeColors';

const SKIP_NOTIFICATION_DAYS = 30;
const NotificationBadge = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { auth, crudState } = useSelector((state: any) => state);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (auth) {
      dispatch(fetchCollection('eventListing', 'events'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const notificationRedirect = (event) => {
    const redirectMap = {
      RECEIPT_CANCELLATION: `${process.env.PUBLIC_URL}/collection-detail/${event.payment_id}`,
      VOIDED_RECEIPT_CANCELLATION: `${process.env.PUBLIC_URL}/collection-detail/${event.payment_id}`,
      SCROLL_DISPUTE: `${process.env.PUBLIC_URL}/scroll-details/${event.scroll_id}`,
      INCENTIVE: `${process.env.PUBLIC_URL}/manage-incentives/add-incentive/${event.incentive_id}`,
      BIDDER_APPROVAL: `${process.env.PUBLIC_URL}/task`,
      REVIEW_REPO_REQUEST: `${process.env.PUBLIC_URL}/repo-initiation/${event.repo_init_id}`,
      REJECTED_REPO_REQUEST: `${process.env.PUBLIC_URL}/repo-initiation/${event.repo_init_id}`,
      APPROVED_REPO_REQUEST: `${process.env.PUBLIC_URL}/repo-initiation/${event.repo_init_id}`,
      INWARD_APPROVAL_REQUEST: `${process.env.PUBLIC_URL}/repoaccounts/${event.acct_number}`,
      REDEMPTION_EXPIRY_REMINDER: `${process.env.PUBLIC_URL}/repoaccounts/${event.acct_number}`,
    };

    const redirectUrl =
      redirectMap[event.action_type] || 'https://wamsuat.tvscredit.com/(S(y4dfbiprop2wtas3vyzgmydw))/Login.aspx';
    window.location.href = redirectUrl;
  };

  const readTask = async (event) => {
    const resp = await update(`events/${event.id}`, {});
    dispatch(fetchCollection('eventListing', 'events'));
    notificationRedirect(event);
  };

  const renderNotificationMessage = (event) => {
    let { message } = event;

    if (event.action_type === 'RECEIPT_CANCELLATION' || event.action_type === 'VOIDED_RECEIPT_CANCELLATION') {
      message = message.replace(/(for\s+)([^\s]+)(\s+has)/, (match, p1, p2, p3) => {
        return `${p1}<b>${p2}</b>${p3}`;
      });
    }

    if (['REVIEW_REPO_REQUEST', 'REJECTED_REPO_REQUEST', 'APPROVED_REPO_REQUEST'].includes(event.action_type)) {
      const messages = message.split('\n').map((m: string) => m?.trim());
      messages[1] = messages[1] ? `<span class="notification-description">${messages[1]}</span>` : '';
      message = messages.join('<br/>');
    }

    return message;
  };

  return (
    <span className="d-flex align-items-center">
      <Button
        id="notification-button"
        aria-controls={open ? 'notification-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ width: '48px', height: '55px', minWidth: '20px' }}
      >
        <StyledBadge badgeContent={crudState?.eventListing?.collections?.payload?.unread} color="primary">
          <HoverDiv style={{ paddingTop: '11px' }}>
            <NotificationsIcon sx={{ width: 32, height: 32, color: '#fff' }} />
          </HoverDiv>
        </StyledBadge>
      </Button>
      <Menu
        id="notification-menu"
        disableScrollLock={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          'aria-labelledby': 'notification-button',
        }}
        PaperProps={{
          className: 'shadow-sm',
        }}
        sx={{
          top: 18,
          paddingBottom: 0,
          '.MuiMenu-paper': {
            borderRadius: '16px',
          },
          '.MuiList-root': {
            padding: 0,
          },
        }}
      >
        <div className="notification-badge-container">
          {crudState?.eventListing?.collections?.payload?.events.length === 0 && (
            <div className="no-notification-container">No Notifications Yet</div>
          )}
          {crudState?.eventListing?.collections?.payload?.events.map((event) => {
            if (moment().diff(moment(event.rec_date), 'days') > SKIP_NOTIFICATION_DAYS) return null;
            return (
              <button
                type="button"
                className="non-styled-button text-left w-100"
                key={`event-${event.id}`}
                onClick={() => {
                  readTask(event);
                }}
              >
                <div
                  key={`event-${event.id}`}
                  className={clsx(
                    'notification-details d-flex align-items-center px-3 py-4 w-100',
                    event.read && 'greyed-out',
                  )}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    className="flex-shrink-0"
                  >
                    <Avatar className="avatar">{event.source_user?.first_name.charAt(0)}</Avatar>
                  </Badge>
                  <div className="flex-grow-1">
                    <div
                      dangerouslySetInnerHTML={{ __html: renderNotificationMessage(event) }}
                      style={{ whiteSpace: 'pre-line' }}
                    />
                    <div className="notification-details-time">{moment(event.rec_date).fromNow()}</div>
                  </div>
                  {!event.read && <Dot className="flex-shrink-0" />}
                </div>
              </button>
            );
          })}
        </div>
      </Menu>
    </span>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 8,
    top: 8,
    minWidth: '16px',
    width: '16px',
    height: '16px',
    fontSize: '10px',
    backgroundColor: '#E15C5C',
  },
}));

const HoverDiv = styles.div`
  width: 48px;
  height: 55px;
  &:hover {
    background-color: #ffffff4d;
  }
`;

const Dot = styles.div`
  width: 12px;
  height: 12px;
  background-color: ${themeColors.Primary500};
  border-radius: 50%;
`;

export default NotificationBadge;
