import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import {
  BORROWER_ROLE,
  CASHIER_ROLE,
  CORPORATE_ROLE,
  DEALER_ROLE,
  SUPERUSER_ROLE,
  Localization,
  MANAGER_ROLE,
  AUDIT_ROLE,
} from 'constant';
import { userLoad, userLogout } from 'redux/auth/actions';
import authProvider from 'utils/authProvider';
import styled from 'styled-components';
import { BreakPoint } from 'utils/screenSize';
import { Link } from 'react-router-dom';
import { clearActionStatus, fetchCollection } from 'redux/crud/action';
import { AppContext, isSideBarMenuUsers } from 'helpers';
import { element } from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const incentiveViewRoles = ['RCM', 'NCM', 'PM'];

type PROPS = {
  size: {
    width: number;
    height: number;
    screen: BreakPoint;
  };
  setShowSidebar: (val: boolean) => void;
  supportChatLoading: boolean;
  onSupportChatClick: () => void;
};

const Sidebar = ({ size, setShowSidebar, supportChatLoading = false, onSupportChatClick }: PROPS) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);
  const history = useHistory();
  const {
    showListingsSubmenu,
    setShowListingsSubmenu,
    showAllocationsSubmenu,
    setShowAllocationsSubmenu,
    showReportsSubmenu,
    setShowReportsSubmenu,
    showAccountsSubmenu,
    setShowAccountsSubmenu,
    showRepoSubmenu,
    setShowRepoSubmenu,
    showCollectionsSubmenu,
    setShowCollectionsSubmenu,
    showIncentiveSubmenu,
    setShowIncentiveSubmenu,
    showAdministrationSubmenu,
    setShowAdministrationSubmenu,
    showLegalSubmenu,
    setShowLegalSubmenu,
    showYardSubmenu,
    setShowYardSubmenu,
    setNotificationsRetrieved,
  } = useContext(AppContext);
  // Submenu toggle handler
  const toggleSubMenu = (event) => {
    switch (event?.name) {
      case 'Collections':
        setShowCollectionsSubmenu(!showCollectionsSubmenu);
        break;
      case 'Teams':
        setShowListingsSubmenu(!showListingsSubmenu);
        break;
      case 'Allocations':
        setShowAllocationsSubmenu(!showAllocationsSubmenu);
        break;
      case 'Accounts':
        setShowAccountsSubmenu(!showAccountsSubmenu);
        break;
      case 'Repo':
        setShowRepoSubmenu(!showRepoSubmenu);
        break;
      case 'Reports':
        setShowReportsSubmenu(!showReportsSubmenu);
        break;
      case 'Incentives':
        setShowIncentiveSubmenu(!showIncentiveSubmenu);
        break;
      case 'Administration':
        setShowAdministrationSubmenu(!showAdministrationSubmenu);
        break;
      case 'Legal':
        setShowLegalSubmenu(!showLegalSubmenu);
        break;
      case 'Yard':
        setShowYardSubmenu(!showYardSubmenu);
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    authProvider.logout();
    dispatch(clearActionStatus('userTc'));

    // clear user info and reset notifications
    dispatch(userLogout());
    dispatch(
      fetchCollection('notifications', 'cms/non-sensitive-notifications', {
        filter: {
          client: Localization.clientName,
        },
      }),
    );
    setNotificationsRetrieved(false);
    history.push('/login');
  };
  const menus = (() => {
    const items = [];
    const sspItems = [];
    const incentiveItems = [];
    const legalItems = [];
    switch (auth?.user?.role) {
      case SUPERUSER_ROLE:
        return [
          {
            name: 'Broadcast',
            items: [
              {
                name: 'Add message',
                href: '/broadcast/add',
                link: '/broadcast/add',
              },
              {
                name: 'View messages',
                href: '/broadcast/view',
                link: '/broadcast/view',
              },
            ],
          },
        ];
      case DEALER_ROLE:
        return [
          {
            name: 'Accounts',
            icon: 'user.png',
            items: [
              {
                name: 'Active',
                href: '/accounts/active',
                link: '/active',
              },
              {
                name: 'Matured',
                href: '/accounts/matured',
                link: '/matured',
              },
            ],
          },
        ];
      case CASHIER_ROLE:
        return [
          {
            name: t('Accounts'),
            href: '/accounts',
            link: '/accounts',
          },
          {
            name: t('Field Records'),
            href: '/field-records',
            link: '/field-records',
          },
          {
            name: t('All Records'),
            href: '/records',
            link: '/records',
          },
          /* eslint-disable */
          Localization.showScrollsTab
            ? {
                name: t('Scroll'),
              href: '/scroll',
                link: '/scroll',
            }
            : {},
          Localization.showRecordsTab
            ? {
                name: t('Records'),
                href: '/transactions',
                link: '/transactions',
              }
            : {},
        ];
      case MANAGER_ROLE:
        // turned off for v6
        items.push({
          name: t(Localization.branch),
          href: '/branches',
          link: '/branches',
        });
        items.push({
          name: t(Localization.collectionSpecialist),
          href: '/specialist',
          link: '/specialist',
        });
        // items.push({
        //   name: 'Accounts', // t('Daily Collections'),
        //   href: '/manage-accounts',
        //   link: '/manage-accounts',
        // });
        // turned off for v6
        // items.push({
        //   name: t('Scroll'),
        //   href: '/scroll-list',
        //   link: '/scroll-list',
        // });
        if (Localization.showCashierPage) {
          items.push({
            name: 'Cashiers', // t('Cashiers'), Removed translation because submenu toggling logic uses name and translation can break it
            href: '/cashiers',
            link: '/cashiers',
          });
        }
        if (auth?.user?.manager_type == 'AUTO') {
          sspItems.push(
            {
              name: 'Manage Users', // t('Daily Collections'),
              href: '/manage-users',
              link: '/manage-users',
            },
            // {
            //   name: 'Manage Clients', // t('Daily Collections'),
            //   href: '/manage-clients',
            //   link: '/manage-clients',
            // },
            // {
            //   name: 'Manage Roles', // t('Daily Collections'),
            //   href: '/manage-roles',
            //   link: '/manage-roles',
            // },
            // {
            //   name: 'Manage Org Structure', // t('Daily Collections'),
            //   href: '/manage-org-structure',
            //   link: '/manage-org-structure',
            // },
            {
              name: 'Manage Banks', // t('Daily Collections'),
              href: '/manage-banks',
              link: '/manage-banks',
            },
            // {
            //   name: 'Manage Tasks', // t('Daily Collections'),
            //   href: '/manage-tasks',
            //   link: '/manage-tasks',
            // },
            {
              name: 'Manage Books', // t('Daily Collections'),
              href: '/manage-books',
              link: '/manage-books',
            },
            {
              name: 'Manage COH Reasons', // t('Daily Collections'),
              href: '/Manage-COH-Reasons',
              link: '/Manage-COH-Reasons',
            },
            {
              name: 'Manage Bidders', // t('Daily Collections'),
              href: '/manage-bidders',
              link: '/manage-bidders',
            },
            {
              name: 'Manage Agency', // t('Daily Collections'),
              href: '/manage-agencies',
              link: '/manage-agencies',
            },
            {
              name: 'Manage Agency User',
              href: '/manage-agency-user',
              link: '/manage-agency-user',
            },
            {
              name: 'Manage Workflow',
              href: '/manage-workflow',
              link: '/manage-workflow',
            },
            // {
            //   name: 'Manage Portfolio', // t('Daily Collections'),
            //   href: '/manage-portfolio',
            //   link: '/manage-portfolio',
            // },
            // {
            //   name: 'Manage Asset', // t('Daily Collections'),
            //   href: '/manage-assets',
            //   link: '/manage-assets',
            // },
            // {
            //   name: 'Manage Yards', // t('Daily Collections'),
            //   href: '/manage-yards',
            //   link: '/manage-yards',
            // },
          );
        } else if (auth?.settings?.show_incentive_program && incentiveViewRoles.includes(auth?.user?.manager_type)) {
          sspItems.push({
            name: 'Manage Derived Metrics', // t('Daily Collections'),
            href: '/manage-metrics',
            link: '/manage-metrics',
          });
        } else {
          sspItems.push({
            name: 'No Access',
            href: '',
            link: '',
          });
        }

        if (auth?.user?.manager_type != 'AGY') {
          legalItems.push(
            {
              name: 'Notice Issuance',
              href: '/notice-issuance',
              link: '/notice-issuance',
            },
            // {
            //   name: 'Legal Visit',
            //   href: '/legal-visit',
            //   link: '/legal-visit',
            // },
          );
        } else {
          legalItems.push({
            name: 'No Access',
            href: '',
            link: '',
          });
        }

        if (auth?.settings?.show_incentive_program && incentiveViewRoles.includes(auth?.user?.manager_type)) {
          incentiveItems.push({
            name: 'Manage Incentives', // t('Daily Collections'),
            href: '/manage-incentives',
            link: '/manage-incentives',
          });
        } else {
          incentiveItems.push({
            name: 'No Access',
            href: '',
            link: '',
          });
        }
        return [
          {
            name: 'Analytics', // t('analytics'),
            href: '/analytics',
            link: '/analytics',
            icon: 'icon-analytics-outline.svg',
          },
          {
            name: 'Accounts', // t('Collections'),
            icon: 'icon-accounts-outline.svg',
            link: '/accounts',
          },
          {
            name: 'Repo',
            icon: 'icon-repo-side menu-unselected.svg',
            items: [
              {
                name: 'Initiation',
                href: '/repo-initiation',
                link: '/repo-initiation',
              },
              {
                name: 'Accounts',
                href: '/repoaccounts',
                link: '/repoaccounts',
              },
            ],
          },
          // turned off for v6
          // {
          //   name: 'Tasks', // t('analytics'),
          //   href: '/task',
          //   link: '/task',
          //   icon: 'clock.png',
          // },
          // turned off for v6
          // {
          //   name: 'Allocations',
          //   icon: 'allocations.png',
          //   items: [
          //     {
          //       name: 'Manual Allocations',
          //       href: '/manual-allocations',
          //       link: '/manual-allocations',
          //     },
          //   ],
          // },
          {
            name: 'Teams', // t('Listings'),
            icon: 'icon-teams-outline.svg',
            items,
          },
          {
            name: 'Reports', // t('Reports'),
            icon: 'icon-reports-outline.svg',
            items: [
              {
                name: 'Daily Collections', // t('Daily Collections'),
                href: '/daily-collections',
                link: '/daily-collections',
              },
              {
                name: 'Downloads', // t('Downloads'),
                href: '/reports-downloads',
                link: '/reports-downloads',
              },
              {
                name: t('Daily Active Collectors'),
                href: '/daily-active-collector',
                link: '/daily-active-collector',
              },
              {
                name: t('Monthly Active Collectors'),
                href: '/monthly-active-collector',
                link: '/monthly-active-collector',
              },
            ],
          },
          // turned off for v6
          // {
          //   name: 'Incentives', // t('Reports'),
          //   icon: 'icon_incentive_submenu.svg',
          //   items: incentiveItems,
          // },
          // turned off for v6
          // {
          //   name: 'Administration', // t('Administration'),
          //   icon: 'icon_administration.svg',
          //   items: sspItems,
          // },
          // turned off for v6
          // {
          //   name: 'Legal',
          //   icon: 'icon_legal.svg',
          //   items: legalItems,
          // },
          // turned off for v6
          // {
          //   name: 'Task',
          //   link: '/manage-tasks',
          //   icon: 'clock.png',
          // },
        ];
      case AUDIT_ROLE:
        // turned off for v6
        items.push({
          name: t(Localization.collectionSpecialist),
          href: '/specialist',
          link: '/specialist',
        });
        items.push({
          name: t('Users'),
          href: '/user-list',
          link: '/user-list',
        });
        if (Localization.showCashierPage) {
          items.push({
            name: 'Cashiers', // t('Cashiers'), Removed translation because submenu toggling logic uses name and translation can break it
            href: '/cashiers',
            link: '/cashiers',
          });
        }
        if (auth?.user?.manager_type == 'AUTO') {
          sspItems.push(
            {
              name: 'Manage Users', // t('Daily Collections'),
              href: '/manage-users',
              link: '/manage-users',
            },
            {
              name: 'Manage Banks', // t('Daily Collections'),
              href: '/manage-banks',
              link: '/manage-banks',
            },
            {
              name: 'Manage Books', // t('Daily Collections'),
              href: '/manage-books',
              link: '/manage-books',
            },
            {
              name: 'Manage COH Reasons', // t('Daily Collections'),
              href: '/Manage-COH-Reasons',
              link: '/Manage-COH-Reasons',
            },
            {
              name: 'Manage Bidders', // t('Daily Collections'),
              href: '/manage-bidders',
              link: '/manage-bidders',
            },
            {
              name: 'Manage Agency', // t('Daily Collections'),
              href: '/manage-agencies',
              link: '/manage-agencies',
            },
            {
              name: 'Manage Agency User',
              href: '/manage-agency-user',
              link: '/manage-agency-user',
            },
            {
              name: 'Manage Workflow',
              href: '/manage-workflow',
              link: '/manage-workflow',
            },
          );
        } else if (auth?.settings?.show_incentive_program && incentiveViewRoles.includes(auth?.user?.manager_type)) {
          sspItems.push({
            name: 'Manage Derived Metrics', // t('Daily Collections'),
            href: '/manage-metrics',
            link: '/manage-metrics',
          });
        } else {
          sspItems.push({
            name: 'No Access',
            href: '',
            link: '',
          });
        }
        if (auth?.user?.manager_type != 'AGY') {
          legalItems.push({
            name: 'Notice Issuance',
            href: '/notice-issuance',
            link: '/notice-issuance',
          });
        } else {
          legalItems.push({
            name: 'No Access',
            href: '',
            link: '',
          });
        }
        if (auth?.settings?.show_incentive_program && incentiveViewRoles.includes(auth?.user?.manager_type)) {
          incentiveItems.push({
            name: 'Manage Incentives', // t('Daily Collections'),
            href: '/manage-incentives',
            link: '/manage-incentives',
          });
        } else {
          incentiveItems.push({
            name: 'No Access',
            href: '',
            link: '',
          });
        }
        return [
          {
            name: 'Accounts', // t('Collections'),
            icon: 'icon-accounts-outline.svg',
            link: '/accounts',
          },
          {
            name: 'Repo',
            icon: 'icon-repo-side menu-unselected.svg',
            link: '/repoaccounts',
          },
          {
            name: 'Teams', // t('Listings'),
            icon: 'icon-teams-outline.svg',
            items,
          },
          {
            name: 'Reports', // t('Reports'),
            icon: 'icon-reports-outline.svg',
            items: [
              {
                name: 'Downloads', // t('Downloads'),
                href: '/reports-downloads',
                link: '/reports-downloads',
              },
              {
                name: t('Daily Active Collectors'),
                href: '/daily-active-collector',
                link: '/daily-active-collector',
              },
              {
                name: t('Monthly Active Collectors'),
                href: '/monthly-active-collector',
                link: '/monthly-active-collector',
              },
            ],
          },
        ];
      default:
        return [];
    }
  })();

  const renderNavMenu = (el: any) => {
    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {el?.icon && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <img
              className="align-self-center"
              src={`${process.env.PUBLIC_URL}/images/${el.icon}`}
              alt="icon"
              width="28"
              height="28"
              onClick={() => toggleSubMenu(el)}
            />
          )}
          {!el?.link ? (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              className="ml-3 align-self-center"
              onClick={() => {
                toggleSubMenu(el);
              }}
              aria-hidden="true"
            >
              {el.name}
            </div>
          ) : (
            <Button
              color="text"
              onClick={(event) => {
                event.stopPropagation();
                if (['md', 's', 'xs'].includes(size.screen)) setShowSidebar(false);
                history.push(el.link);
              }}
            >
              {el.name}
            </Button>
          )}
        </div>
        {renderArrow(el, el?.items, el?.name)}
      </div>
    );
  };

  const renderArrow = (el, items, name) => {
    if (items) {
      if (
        (name === 'Collections' && showCollectionsSubmenu) ||
        (name === 'Teams' && showListingsSubmenu) ||
        (name === 'Accounts' && showAccountsSubmenu) ||
        (name === 'Repo' && showRepoSubmenu) ||
        (name === 'Reports' && showReportsSubmenu) ||
        (name === 'Incentives' && showIncentiveSubmenu) ||
        (name === 'Administration' && showAdministrationSubmenu) ||
        (name === 'Legal' && showLegalSubmenu) ||
        (name === 'Yard' && showYardSubmenu)
      ) {
        return <KeyboardArrowUpIcon onClick={() => toggleSubMenu(el)} aria-hidden="true" />;
      }
      return <KeyboardArrowDownIcon onClick={() => toggleSubMenu(el)} aria-hidden="true" />;
    }
    return <></>;
  };

  const route = useRouteMatch();
  console.log('route match', route.path);
  const activeRoute = route.path === '/' ? '/analytics' : route.path;
  return (
    <SidebarComponent className="p-0 d-flex flex-column justify-content-between" style={{ overflow: 'scroll' }}>
      <div style={{ textAlign: 'center' }}>
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
            alt={Localization.clientName}
            // height="45"
            width="130"
            height="36"
            style={{ marginTop: '15px' }}
          />
        </Link>
        <ListGroupStyle>
          {menus.map((el: any) => (
            <>
              <ListGroupItem
                tag={el.href ? 'a' : 'div'}
                href={el?.href}
                key={el.name}
                className={`parent ${activeRoute.includes(el.link) ? 'is-active' : ''}`}
              >
                {renderNavMenu(el)}
                {el?.items &&
                  ((el?.name === 'Teams' && showListingsSubmenu) ||
                    (el?.name === 'Allocations' && showAllocationsSubmenu) ||
                    (el?.name === 'Collections' && showCollectionsSubmenu) ||
                    (el?.name === 'Accounts' && showAccountsSubmenu) ||
                    (el?.name === 'Repo' && showRepoSubmenu) ||
                    (el?.name === 'Reports' && showReportsSubmenu) ||
                    (el?.name === 'Incentives' && showIncentiveSubmenu) ||
                    (el?.name === 'Administration' && showAdministrationSubmenu) ||
                    (el?.name === 'Legal' && showLegalSubmenu) ||
                    (el?.name === 'Yard' && showYardSubmenu)) && (
                    <ListGroupStyle>
                      {el?.items?.map((child: any, index: number) => (
                        <ListGroupItem
                          key={child.href}
                          className={`sub ${activeRoute.includes(child.link) ? 'is-active' : ''}`}
                        >
                          <ul style={{ paddingLeft: '20px' }}>
                            <li
                              style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                listStyle: 'disc',
                                color: '#999999',
                              }}
                            >
                              {renderNavMenu(child)}
                            </li>
                          </ul>
                        </ListGroupItem>
                      ))}
                    </ListGroupStyle>
                    // eslint-disable-next-line @typescript-eslint/indent
                  )}
              </ListGroupItem>
            </>
          ))}
        </ListGroupStyle>
      </div>
      <Footer>
        <div className="d-flex justify-content-center">
          <div style={{ width: '60px', height: '31px', marginLeft: '-5px' }}>
            <img src={`${process.env.PUBLIC_URL}/images/powered_by.svg`} alt="TVS Digital" height="31" width="60" />
          </div>
        </div>
        <Hrline />
        <SupportChatSection onClick={() => onSupportChatClick && !supportChatLoading && onSupportChatClick()}>
          <img src={`${process.env.PUBLIC_URL}/images/icon-help-outline.svg`} alt="Icon Help" />
          <SupportChatSectionTxt>{supportChatLoading ? 'Loading Chat...' : 'Support Chat'}</SupportChatSectionTxt>
        </SupportChatSection>
        <Hrline />
        <div className="d-flex justify-content-center gap-2 p-[8px]">
          <LegalLink href={Localization.PRIVACY_POLICY_URL} target="_blank">
            Privacy Policy
          </LegalLink>
          <LegalLink href={Localization.TERMS_OF_USE_URL} target="_blank">
            Terms Of Use
          </LegalLink>
          <LegalLink href={Localization.COOKIE_POLICY_URL} target="_blank">
            Cookie Policy
          </LegalLink>
        </div>
      </Footer>
    </SidebarComponent>
  );
};
const Hrline = styled.hr`
  border: 0;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const SidebarComponent = styled.div`
  box-shadow: 0;
  background-color: #fff;
  width: 256px;
  position: fixed;
  height: calc(100vh - 72px);
  @media (max-width: 820px) {
    position: static;
    width: 100%;
    flex: 0 0 100%;
  }
`;
const ListGroupStyle = styled(ListGroup)`
  margin-top: 20px;
  .list-group {
    margin: 0;
  }
  .list-group-item {
    background-color: transparent;
    padding: 0;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid transparent;
    margin-left: -1px;
    .btn {
      font-weight: 500;
      color: #333333;
    }
    &.sub {
      padding-left: 27px;
      .btn {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
      &.is-active {
        a,
        .btn {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
        border-radius: 0px 32px 32px 0px;
        background-color: #e8e9f9;
        margin-right: 8px;
      }
    }
    > div {
      padding: 15px;
      font-weight: 500;
      color: #333333;
      align-self: center;
    }
    &.parent.is-active {
      > div {
        border-radius: 100px;
        background-color: #e8e9f9;
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }
`;
const Footer = styled.div`
  margin-bottom: 10px;
`;
const FooterText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #333333;
`;
const LegalLink = styled.a`
  font-weight: 400;
  font-size: 8px;
  line-height: 15px;
  margin-right: 0.5rem;
  font-family: Noto Sans, sans-serif;
  font-variation-settings: 'wdth' 100;
`;
const SupportChatSection = styled.div`
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const SupportChatSectionTxt = styled.span`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  color: #3548c9;
`;

export default Sidebar;
